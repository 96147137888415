import React from "react"

import { graphql } from "gatsby"

import Seo from "../components/Seo"
import Layout from "../components/Layout"

import Grid from "../components/Grid"
import SafeAreaWrapper from "../components/SafeAreaWrapper"
import Introduction from "../components/Introduction"
import { BlueBanner } from "../components/Banner"

import { Button } from "../components/Button"

import SmallStage from "../components/SmallStage"
import Title from "../components/Title"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

const WallpapersPage = ({ data: { backgroundImage, page } }) => {
	return (
		<Layout translucent={false}>
			<Seo
				title={page.seo.title}
				description={page.seo.description}
				keywords={page.seo.keywords}
			/>

			<SmallStage className="pt-[60px] md:pt-[80px]">
				<Title title="Wallpapers and Backdrops" small={true} tint={false} />
			</SmallStage>

			<div className="my-spacer-xl">
				<SafeAreaWrapper>
					<Introduction>
						Browse our curated list of home-made wallpapers and meeting backdrops.
						Download your favorite virtual backgrounds and add them to your desktop and
						meetings!
					</Introduction>
					<Grid className="gap-20 md:gap-40 mt-spacer-lg">
						{page.wallpapers.map(({ name, preview, mobile, fullhd }, index) => (
							<div
								className="group col-span-12 lg:col-span-6 xl:col-span-4 bg-porcelain rounded-medium p-20 flex flex-col items-center relative overflow-hidden"
								key={`wallpaper-${index}`}>
								<div className="overflow-hidden rounded-medium group-hover:opacity-10 transition-opacity duration-250 ease-in-out">
									<GatsbyImage
										image={getImage(preview?.localFile)}
										alt={preview?.alternativeText}
										title={name}
									/>
								</div>

								<div className="absolute left-0 right-0 top-0 bottom-0 w-full hidden group-hover:flex flex-col gap-y-20 items-center justify-center">
									{fullhd && (
										<a
											href={fullhd.localFile.publicURL}
											title={fullhd.localFile.publicURL}
											data-umami-event="download-desktop-wallpaper"
											download>
											<Button primary={true} label="Desktop wallpaper" />
										</a>
									)}
									{mobile && (
										<a
											href={mobile.localFile.publicURL}
											title={mobile.localFile.publicURL}
											data-umami-event="download-mobile-wallpaper"
											download>
											<Button primary={false} label="Mobile wallpaper" />
										</a>
									)}
								</div>
							</div>
						))}
					</Grid>

					<BlueBanner
						className="my-spacer-xl"
						title={page.banner.title}
						subtitle={page.banner.pretext}
						image={
							page.banner.screenshot
								? getImage(page.banner.screenshot.localFile)
								: null
						}
						callToAction="Schedule a demo"
						callToActionUrl="/demo"
					/>
				</SafeAreaWrapper>
			</div>
		</Layout>
	)
}

export const pageQuery = graphql`
	query {
		backgroundImage: file(relativePath: { eq: "gradient.png" }) {
			childImageSharp {
				gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
			}
		}

		page: strapiWallpaperPage {
			seo {
				title
				description
				keywords
			}

			banner {
				pretext
				title
				screenshot {
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED, width: 760)
						}
					}
				}
			}

			wallpapers {
				name
				preview: desktop {
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(height: 240)
						}
						publicURL
					}
				}

				mobile {
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(width: 1080, height: 1920)
						}
						publicURL
					}
				}
				fullhd: desktop {
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(width: 1920, height: 1080)
						}
						publicURL
					}
				}
			}
		}
	}
`

export default WallpapersPage
